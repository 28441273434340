import React from 'react'
import { Link } from 'react-router-dom'
const NavBar = (props) => {
    let shop = "Velskoendraai SHOP"
    return (
        <nav className="navbar navbar-expand-lg fixed-top navbar-dark" style={{ "backgroundColor": "#6C7C71" }}>
            
                {props.searchResultsShow?
                    <div className="container">
                        <div className="navbar-brand">
                            <button className="nav-link" 
                                style={{ "width": "auto", "padding": "0","color":"#ffffff","backgroundColor":"#6C7C71" }} 
                                onClick={() => props.setSelection('setCatId', 0)}><i className="fas fa-arrow-left"></i> Back</button>
                        </div>
                    </div>
                :
                <div className="container">
                    <div className="navbar-brand">
                            <button className="nav-link" 
                                style={{ "width": "auto", "padding": "0","color":"#ffffff","backgroundColor":"#6C7C71" }} 
                                onClick={() => props.setSelection('setCatId', 0)}>{shop}</button>
                            
                        </div><a className="nav-link" href="https://menu.velskoendraai.co.za" style={{"color":"#ffffff"}} target="_blank" rel="noopener noreferrer"><i className="fas fa-utensils"></i> MENU</a>
                    <form className="form-inline my-2 my-sm-0">
                        <input type="text" 
                            className="form-control" 
                            placeholder="Search..." 
                            name="searchString" 
                            onChange={(e)=>props.changeHandler(e)} 
                            onKeyPress={e => {
                                if (e.key === 'Enter') {
                                    props.searchCostItems(e)
                                }
                            }}
                            value={props.searchString} 
                            style={{"width":"100px","backgroundColor":"#6C7C71","color":"#ffffff"}} 
                        />
                        <button type="button" className="btn btn-default" style={{"backgroundColor":"#6C7C71"}} onClick={(e)=>props.searchCostItems(e)}><i className="fas fa-search"></i></button>
                        <Link className="nav-link" to="/user" style={{"color":"#dedede"}}><i className="fas fa-user"></i> {props.buyer.webid > 0? props.buyer.naam : "Login"}</Link>
                        {props.cartCostItems.length > 0? <Link className="btn btn-warning" to="/cart"><i className="fas fa-shopping-cart"></i> {props.cartCostItems.length}</Link>:null}
                    </form>
                </div>}
        </nav>
    )
}

export default NavBar