import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import ResetMessage from './ResetMessage'

class Reset extends Component {

    state = {
        resetEmail: '',
        webid: 0,
        resetCode: '',
        step: 1,
        pw1: '',
        pw2: '',
        working: false,
        responseMessage: 'Please enter your e-mail address below'
    }

    handleReset = (e) => {
        e.preventDefault()

        if (this.state.pw1 === this.state.pw2) {
            this.setState({
                working: true
            })

            let currentStep = this.state.step
            let nextStep = currentStep + 1


            let authConfig = {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + window.btoa(this.state.resetEmail)
            }

            //console.log(this.state)
            axios.post(this.props.shopUrl + '' + this.props.apiRoot + '/reset.php', {
                resetEmail: this.state.resetEmail,
                webid: this.state.webid,
                resetCode: this.state.resetCode,
                step: currentStep,
                pw1: window.btoa(this.state.pw1),
                action: 'reset'
            }, { headers: authConfig })
                .then(res => {
                    //console.log(res.data)
                    if (res.data[0].success) {
                        //set state for next step
                        if (currentStep === 1) {
                            this.setState({
                                webid: res.data[0].webid,
                                step: nextStep,
                                working: false,
                                responseMessage: res.data[0].msg
                            })
                        } else {
                            this.setState({
                                step: nextStep,
                                working: false,
                                responseMessage: res.data[0].msg
                            })
                        }

                    } else {
                        if (res.data[0].error) {
                            this.setState({
                                resetCode: '',
                                webid: 0,
                                working: false,
                                step: 1,
                                pw1: '',
                                pw2: '',
                                responseMessage: res.data[0].msg
                            })
                        }
                    }
                })
        } else {
            this.setState({
                responseMessage: 'Password fields do not match'
            })
        }


    }

    render() {
        return (
            <div className="container">
                {this.props.working ?
                    <div className="container">
                        <div className="spacer"></div>
                        <div className="d-flex align-items-center">
                            <strong>Loading...</strong>
                            <div className="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                        </div>
                    </div> : null
                }
                <div className="row">
                    <div className="col-md-2">
                        <div>
                            <Link className="btn btn-link" to="/" style={{ "width": "auto", "padding": "0" }}>Home > </Link>
                            <span className="btn btn-link" style={{ "width": "auto", "padding": "0", "fontWeight": "bold" }}>FORGOT PASSWORD</span>
                        </div>
                        <div className="spacer"></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        {this.state.step === 1 ?
                            <form method="post" name="form" onSubmit={(e) => this.handleReset(e)}>
                                <h2>Reset password</h2>
                                <ResetMessage responseMessage={this.state.responseMessage} />
                                <div className="form-group row">
                                    <label htmlFor="username" className="col-md-4 col-form-label text-md-left">E-mail</label>
                                    <div className="col-md-6">
                                        <input type="email" className="form-control" required name="resetEmail"
                                            value={this.state.resetEmail}
                                            onChange={(e) => this.setState({ resetEmail: e.target.value })} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="btn" className="col-md-4 col-form-label text-md-left">&nbsp;</label>
                                    <div className="col-md-6"><button className="btn btn-warning">Send Reset Code</button></div>
                                </div>
                            </form>
                            : null}

                        {this.state.step === 2 ?
                            <form method="post" name="form" onSubmit={(e) => this.handleReset(e)}>
                                <h2>Please enter code</h2>
                                <ResetMessage responseMessage={this.state.responseMessage} />
                                <div className="form-group row">
                                    <label htmlFor="username" className="col-md-4 col-form-label text-md-left">Reset Code</label>
                                    <div className="col-md-6">
                                        <input type="text" className="form-control" required name="resetCode"
                                            value={this.state.resetCode}
                                            onChange={(e) => this.setState({ resetCode: e.target.value })} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="btn" className="col-md-4 col-form-label text-md-left">&nbsp;</label>
                                    <div className="col-md-6"><button className="btn btn-warning">Validate Code</button></div>
                                </div>
                            </form>
                            : null}

                        {this.state.step === 3 ?
                            <form method="post" name="form" onSubmit={(e) => this.handleReset(e)}>
                                <h2>Please enter new password</h2>
                                <ResetMessage responseMessage={this.state.responseMessage} />
                                <div className='form-group row'>
                                    <label htmlFor="password" className="col-md-4 col-form-label text-md-left">Password</label>
                                    <div className="col-md-6">
                                        <input type="password"
                                            className="form-control"
                                            autoComplete="new-password"
                                            name="pw1"
                                            value={this.state.pw1}
                                            required
                                            onChange={(e) => this.setState({ pw1: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className='form-group row'>
                                    <label htmlFor="wagwoord2" className="col-md-4 col-form-label text-md-left">Retype Password</label>
                                    <div className="col-md-6"><input type="password"
                                        className="form-control"
                                        autoComplete="new-password"
                                        name="pw2"
                                        value={this.state.pw2}
                                        required
                                        onChange={(e) => this.setState({ pw2: e.target.value })}
                                    /></div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="btn" className="col-md-4 col-form-label text-md-left">&nbsp;</label>
                                    <div className="col-md-6"><button className="btn btn-warning">Save</button></div>
                                </div>
                            </form>
                            : null}

                        {this.state.step === 4 ?
                            <div>
                                <h2>Password saved</h2>
                                <ResetMessage responseMessage={this.state.responseMessage} />
                                <p><Link className="btn btn-default" to="/user">Login now</Link></p>
                            </div>
                            : null}
                    </div>
                </div>
            </div>
        )

    }
}

export default Reset