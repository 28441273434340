import React from 'react'

const SuccessMessage = (props) => {
    return (

        <div className="alert alert-info">
            <h3>Success!</h3><p>{props.successMessage}</p>
            <p><button className="btn btn-secondary" onClick={() => props.clearSuccessMessage()}><i className="far fa-window-close"></i> Close</button></p>
        </div>

    )
}

export default SuccessMessage