import React from 'react'

const Footer = () => {
    return (
        <div style={{ "padding": "15px", "backgroundColor": "#eeeeee", "textAlign": "center", "color": "#999999", "fontSize": "80%" }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg"><p>+27 (0)83 460 3773 &bull; info@velskoendraai.co.za<br />
Graafwater Road, Clanwilliam<br />
Western Cape, South Africa</p></div>
                    <div className="col-lg"><p>Velskoendraai Farmers Market<br />Rooibos Tea Tasting Experience<br />Gift Shop / Farmers Market<br />Fresh Goods Farmstall</p></div>
                    <div className="col-lg" style={{ "textAlign": "center", "fontSize": "90%" }}>
                        <img src="/logoIntro.png" style={{ "width": "100%", "maxWidth": "90px" }} alt="Velskoendraai" />
                    </div>
                    <div className="col-lg">
                        <div style={{ "textAlign": "center", "fontSize": "90%" }}>
                        
                            <img src="/payments.png" style={{ "width": "100%", "maxWidth": "100px" }} alt="" /><br />
                            <img src="/aramex.png" style={{ "width": "100%", "maxWidth": "100px" }} alt="Aramex" />
                            
                        </div> 
                    </div>
                    
                    <div className="col-lg">
                    <img src="/letsencrypt.png" style={{ "width": "100%", "maxWidth": "110px" }} alt="Let's Encrypt" /><br />
                        <a href="https://www.aarbei.co.za/" target="_blank" rel="noopener noreferrer">

                            <img src="https://www.aarbei.co.za/branding/aarbei.co.za_gray.png" style={{"maxWidth": "110px", "verticalAlign": "middle" }} alt="Hosting and Development" />
                        </a>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Footer