import React from 'react'

function nl2br(str){
    return str.replace(/(?:\r\n|\r|\n)/g, '~');
}
const PrettyDeliveryAddress = (props) => {
    let uncleanedAddress = props.sdeladres
    let deladresForHtml = nl2br(uncleanedAddress)
    let delArray = deladresForHtml.split('~')

    return (delArray.map(e => {
        return (<div key={e}>{e}</div>)
    }))
}

export default PrettyDeliveryAddress