import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import NavBar from './components/NavBar'
import Container from './components/Container'
import Footer from './components/Footer';
import Intro from './components/Intro'
import Search from './components/Search'
import User from './components/User'
import UserProfile from './components/UserProfile'
import Cart from './components/Cart'
import ShopItemsBrowser from './components/ShopItemsBrowser'
import Reset from './components/Reset'
import Success from './components/Success'
import axios from 'axios';

const shopUrl = 'https://shop.velskoendraai.co.za/'
//const shopUrl = 'http://localhost/'
const apiRoot = '_blipshopAPI'
const imgRoot = 'Products'

class ShopApp extends Component {

  myRef = React.createRef()

  state = {
    buyer: { webid: 0, eposadres: '', naam: '', van: '', sdeladres: '', scell: '', delcountry: 'South Africa', extra: '' },
    orders: [],
    wagwoord: '',
    cartCostItems: [],
    paymentMethod: 'EFT',
    deliveryMethod: 'COURIER',
    deliveryCost: 100,
    categories: [],
    subcategories: [],
    costitems: [],
    selectedCatId: 0,
    selectedSubCatId: 0,
    selectedCostitemId: 0,
    saving: false,
    submitting: false,
    loading: false,
    loggingIn: false,
    errorMessage: '',
    successMessage: '',
    searchString: '',
    searchResultsShow: false
  }

  sortMyArrayOnDescription = (arr) => {
    arr.sort((a, b) => {
      if (a.description > b.description) {
        return 1
      } else {
        if (a.description < b.description) {
          return -1
        } else {
          return 0
        }
      }
    })
    return arr
  }

  submitOrder = () => {

    this.setState({
      submitting: true,
    })

    let authConfig = {}
    authConfig = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + window.btoa(this.state.buyer.sessionToken)
    }

    let TotalOrderValue = document.getElementById('TotalOrderValue').value

    axios.post(shopUrl + '' + apiRoot + '/order.php', {
      buyer: this.state.buyer,
      cartCostItems: this.state.cartCostItems,
      paymentMethod: this.state.paymentMethod,
      deliveryMethod: this.state.deliveryMethod,
      deliveryCost: this.state.deliveryCost,
      TotalOrderValue: TotalOrderValue,
      action: 'submitorder'
    }, { headers: authConfig })
      .then(res => {
        //console.log(res.data)
        if (res.data.length > 0) {
          if (res.data[0].error) {
            this.setState({
              errorMessage: res.data[0].msg,
              submitting: false,
            })
          } else {
            this.setState({
              submitting: false,
              successMessage: 'Your Order was created. Please check your mailbox for a copy of your order.',
              cartCostItems: [],
              orders: res.data
            })
          }
        }
      })
      .catch(err => {
        console.log(err)
      })

  }
  setupUserSession = () => {
    let eposadres, q = ''

    if (localStorage.getItem('eposadres')) {
      eposadres = localStorage.getItem('eposadres')
      q = "1"
    } else {
      eposadres = this.state.buyer.eposadres
      q = "0"
    }

    if (eposadres) {
      let authConfig = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + window.btoa(eposadres)
      }

      axios.post(shopUrl + '' + apiRoot + '/lmi.php', {
        eposadres: eposadres,
        wagwoord: window.btoa(this.state.wagwoord),
        userLogin: q
      }, { headers: authConfig })
        .then(res => {
          //console.log(res.data)
          if (res.data.length === 1) {
            if (!res.data[0].error) {
              let delcost = 100
              if (res.data[0].delcountry !== 'South Africa') {
                delcost = 250
              }
              this.setState({
                buyer: {
                  //...this.state.buyer,
                  eposadres: eposadres,
                  webid: res.data[0].webid,
                  naam: res.data[0].naam,
                  van: res.data[0].van,
                  scell: res.data[0].scell,
                  sdeladres: res.data[0].sdeladres,
                  sessionToken: res.data[0].sessionToken,
                  delcountry: res.data[0].delcountry,
                  extra:''
                
                },
                deliveryCost: delcost,
                loggingIn: false
              })
            } else {
              this.setState({
                errorMessage: res.data[0].msg,
                loggingIn: false
              })
            }
          }
        }).then(() => {
          if (this.state.buyer.webid === 0) {
            localStorage.clear();
          } else {
            localStorage.setItem('eposadres', this.state.buyer.eposadres)
          }

        })
        .catch(err => {
          console.log(err)
        })
    }



  }

  handleProfileSubmit = (e) => {
    e.preventDefault()

    this.setState({
      saving: true
    })

    let authConfig = {}
    if (document.getElementById('action').value === 'update') {
      authConfig = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + window.btoa(this.state.buyer.sessionToken)
      }
    } else {
      authConfig = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + window.btoa(this.state.buyer.eposadres)
      }
    }

    axios.post(shopUrl + '' + apiRoot + '/user.php', {
      eposadres: this.state.buyer.eposadres,
      wagwoord: window.btoa(this.state.wagwoord),
      webid: this.state.buyer.webid,
      naam: this.state.buyer.naam,
      van: this.state.buyer.van,
      scell: this.state.buyer.scell,
      sdeladres: this.state.buyer.sdeladres,
      delcountry: this.state.buyer.delcountry,
      action: document.getElementById('action').value
    }, { headers: authConfig })
      .then(res => {
        if (res.data.length === 1) {
          if (res.data[0].error) {
            this.setState({
              buyer: {
                eposadres: '',
                webid: 0,
                naam: '',
                van: '',
                scell: '',
                sdeladres: '',
                sessionToken: '',
                extra: ''
              },
              saving: false,
              errorMessage: res.data[0].msg
            })
          } else {
            this.setState({
              buyer: {
                eposadres: res.data[0].eposadres,
                webid: res.data[0].webid,
                naam: res.data[0].naam,
                van: res.data[0].van,
                scell: res.data[0].scell,
                sdeladres: res.data[0].sdeladres,
                sessionToken: res.data[0].sessionToken,
                delcountry: res.data[0].delcountry,
                extra: ''
              },
              saving: false
            })
          }
        }
      })
      .then(() => {
        if (this.state.buyer.webid === 0) {
          localStorage.clear();
        } else {
          localStorage.setItem('eposadres', this.state.buyer.eposadres)
        }
      })
      .catch(err => {
        console.log(err)
      })

  }

  handleLoginSubmit = (e) => {
    e.preventDefault();

    this.setState({
      loggingIn: true
    })

    this.setupUserSession()

  }

  handleLogout = () => {
    this.setState({
      buyer: { webid: 0, eposadres: '', naam: '', van: '', sdeladres: '', scell: '', delcountry: 'South Africa', extra: '' },
      orders: [],
      cartCostItems: []
    })
    localStorage.clear();
  }

  cleanString = (s) => {
    let x = s.replace("'", "`")
    return x.replace(";", ",")

  }

  changeHandler = (e) => {
    const { name, value } = e.target;
    let prefix = name.substr(0, 6)
    let propname = name.substr(6)
    let cleanedValue = this.cleanString(value)

    if (prefix === "buyer.") {
      this.setState({
        buyer: {
          ...this.state.buyer,
          [propname]: cleanedValue
        }
      })
    } else {
      this.setState({ [name]: cleanedValue });
    }
  }

  changeQtyHandler = (event) => {
    //name = costitemid
    const { name, value } = event.target

    let filteredItems = this.state.cartCostItems.filter(e => e.costitemid !== name)
    let itemToChange = this.state.cartCostItems.filter(e => e.costitemid === name)

    if (itemToChange.length === 1) {
      itemToChange[0].qty = value
    }

    let newArray = [...filteredItems, ...itemToChange]
    newArray = this.sortMyArrayOnDescription(newArray)

    this.setState({
      cartCostItems: newArray
    })

  }
  atc = (costitemid) => {
    let oldCartCostItems = this.state.cartCostItems.filter(e => e.costitemid !== costitemid)
    let newCartCostItem = this.state.costitems.filter(e => e.costitemid === costitemid)
    let newArray = [...oldCartCostItems, ...newCartCostItem]

    newArray = this.sortMyArrayOnDescription(newArray)

    this.setState({
      cartCostItems: newArray
    })
  }

  rfc = (costitemid) => {
    let newCartCostItem = this.state.cartCostItems.filter(e => e.costitemid !== costitemid)

    this.setState({
      cartCostItems: newCartCostItem
    })
  }

  searchCostItems = (e) => {
    e.preventDefault()

    let mySearchString = this.cleanString(this.state.searchString)

    if (this.state.searchString.length > 2) {

      this.setState({
        loading: true,
        errorMessage: '',
        selectedCatId: 0,
        selectedSubCatId: 0,
        selectedCostitemId: 0,
        costitems: [],
        subcategories: [],
        searchResultsShow: false
      })

      axios.get(shopUrl + apiRoot + '/?info=search&s=' + mySearchString)
        .then((res => {
          //console.log(res.data)
          if (res.data.length > 0) {
            this.setState({
              costitems: res.data,
              loading: false,
              searchResultsShow: true,
            })
          } else {
            this.setState({
              errorMessage: 'No results found for ' + mySearchString,
              loading: false,
              searchString: ''
            })
          }
        }))
        .catch(err => {
          console.log(err)
        })
    }
    else {
      this.setState({
        errorMessage: 'Search string too small, please enter more text.',
        costitems: [],
        subcategories: [],
        selectedCatId: 0,
        selectedSubCatId: 0,
        selectedCostitemId: 0
      })
    }

  }

  loadCostItems = (selectedSubCatId) => {

    axios.get(shopUrl + apiRoot + '/?info=costitems&subcat_id=' + selectedSubCatId)
      .then((res => {
        //console.log(res.data)
        if (res.status === 200) {
          this.setState({
            costitems: res.data,
            loading: false,
          })
        }
      }))
      .catch(err => {
        console.log(err)
      })

  }
  loadSubCategories = (selectedCatId) => {
    axios.get(shopUrl + apiRoot + '/?info=subcategories&cat_id=' + selectedCatId)
      .then((res => {
        //console.log(res.data)
        if (res.status === 200) {
          this.setState({
            subcategories: res.data,
            loading: false
          })
        }
      }))
      .catch(err => {
        console.log(err)
      })
  }

  loadOrders = () => {

    let authConfig = {}
    authConfig = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + window.btoa(this.state.buyer.sessionToken)
    }

    axios.post(shopUrl + '' + apiRoot + '/orders.php', {
      webid: this.state.buyer.webid,
      action: 'loadorders'
    }, { headers: authConfig })
      .then(res => {
        //console.log(res.data)
        if (res.data.length > 0) {
          //console.log(res.data)
          if (res.data[0].error) {
            this.setState({
              errorMessage: res.data[0].msg,
              orders: []
            })
          } else {
            this.setState({
              orders: res.data
            })
          }
        }
      })
      .catch(err => {
        console.log(err)
      })

  }
  setSelection = (action, value) => {
    //console.log(action, value)
    if (action === 'setCostitemId') {
      this.setState({
        selectedCostitemId: value
      })
    } else {
      if (action === 'setCatId') {
        if (value === 0) {
          this.setState({
            selectedCatId: 0,
            selectedSubCatId: 0,
            selectedCostitemId: 0,
            searchResultsShow: false,
            searchString: '',
            subcategories: [],
            costitems: []
          })
        } else {
          this.setState({
            loading: true,
            selectedCatId: value,
            selectedCostitemId: 0,
            searchResultsShow: false,
            searchString: '',
          })

          this.loadSubCategories(value)
        }

      } else {
        if (action === 'setSubCatId') {
          if (value === 0) {
            this.setState({
              selectedSubCatId: 0,
              selectedCostitemId: 0,
              costitems: [],
              searchResultsShow: false,
              searchString: '',
            })

          } else {

            this.setState({
              loading: true,
              selectedSubCatId: value,
              selectedCostitemId: 0,
              searchResultsShow: false,
              searchString: '',
            })
            this.loadCostItems(value)

          }
        }
      }
    }

  }

  setPaymentMethod = (val) => {
    this.setState({
      paymentMethod: val
    })
  }

  setDeliveryMethod = (val) => {
    let cost = 100
    if (val === 'COLLECT') {
      cost = 0
    } else {
      if (this.state.buyer.delcountry !== 'South Africa') {
        cost = 250
      }
    }
    this.setState({
      deliveryMethod: val,
      deliveryCost: cost
    })
  }

  clearErrorMessage = () => {
    this.setState({
      errorMessage: ''
    })
    
  }

  
  clearSuccessMessage = () => {
    this.setState({
      successMessage: ''
    })
    //this.props.history.push('/user')
  }

  componentDidMount() {

    axios.get(shopUrl + apiRoot + '/?info=categories')
      .then((res => {

        if (res.status === 200) {
          this.setState({
            categories: res.data
          })
        }
      }))
      .then(() => {
        this.setupUserSession()
      })
      .catch(err => {
        console.log(err)
      })
  }

  render() {
    return (
      <Router>
        <NavBar cartCostItems={this.state.cartCostItems}
          buyer={this.state.buyer}
          searchString={this.state.searchString}
          searchResultsShow={this.state.searchResultsShow}
          searchCostItems={this.searchCostItems}
          changeHandler={this.changeHandler}
          setSelection={this.setSelection}
        />

        <Switch>
          <Route exact path="/success">
              <Container component={Success} buyer={this.state.buyer} apiRoot={apiRoot} shopUrl={shopUrl} />
          </Route>
          <Route exact path="/search">
            <Container component={Search} />
          </Route>
          <Route exact path="/reset">
            <Container component={Reset} shopUrl={shopUrl} apiRoot={apiRoot} />
          </Route>
          <Route exact path="/user">
          {this.state.searchResultsShow? 
            <Redirect to="/" />
            :
            <Container component={User}
              buyer={this.state.buyer}
              changeHandler={this.changeHandler}
              handleLoginSubmit={this.handleLoginSubmit}
              handleLogout={this.handleLogout}
              loggingIn={this.state.loggingIn}
              errorMessage={this.state.errorMessage}
              clearErrorMessage={this.clearErrorMessage}
              cartCostItems={this.state.cartCostItems}
              loadOrders={this.loadOrders}
              orders={this.state.orders}
              shopUrl={shopUrl}
            />}
          </Route>
          <Route exact path="/profile">
           {this.state.searchResultsShow? 
            <Redirect to="/" />
            :
            <Container component={UserProfile}
              buyer={this.state.buyer}
              changeHandler={this.changeHandler}
              handleProfileSubmit={this.handleProfileSubmit}
              handleLogout={this.handleLogout}
              saving={this.state.saving}
              errorMessage={this.state.errorMessage}
              clearErrorMessage={this.clearErrorMessage}
            />}
          </Route>
          <Route exact path="/cart">
          {this.state.successMessage === '' && this.state.cartCostItems.length === 0?
            <Redirect to="/user" />
            :
            <Container component={Cart} 
              cartCostItems={this.state.cartCostItems}
              setSelection={this.setSelection}
              changeQtyHandler={this.changeQtyHandler}
              changeHandler={this.changeHandler}
              rfc={this.rfc}
              errorMessage={this.state.errorMessage}
              clearErrorMessage={this.clearErrorMessage}
              buyer={this.state.buyer}
              paymentMethod={this.state.paymentMethod}
              setPaymentMethod={this.setPaymentMethod}
              deliveryMethod={this.state.deliveryMethod}
              deliveryCost={this.state.deliveryCost}
              setDeliveryMethod={this.setDeliveryMethod}
              submitOrder={this.submitOrder}
              submitting={this.state.submitting}
              successMessage={this.state.successMessage}
              clearSuccessMessage={this.clearSuccessMessage}
              extra={this.state.extra}
            />
            }
          </Route>
          <Route path="/">
          
            <Container myRef={this.myRef}
              component={ShopItemsBrowser}
              categories={this.state.categories}
              subcategories={this.state.subcategories}
              costitems={this.state.costitems}
              imgUrl={shopUrl + imgRoot}
              errorMessage={this.state.errorMessage}
              clearErrorMessage={this.clearErrorMessage}
              selectedCatId={this.state.selectedCatId}
              selectedSubCatId={this.state.selectedSubCatId}
              selectedCostitemId={this.state.selectedCostitemId}
              setSelection={this.setSelection}
              loading={this.state.loading}
              atc={this.atc}
              searchString={this.state.searchString}
              searchResultsShow={this.state.searchResultsShow}
            />
            {this.state.subcategories.length === 0 ?
              <div>
                <Intro />
                
              </div> : null
            }
          </Route>
        </Switch>

        <Footer />
      </Router>

    );
  }

}

export default ShopApp;
