import React from 'react'

const Intro = () => {

    return (
        <div className="container-fluid" style={{ "backgroundColor": "#EADED7" }}>
            <div className="container">
                <div className="row">
                    
                    <div className="col-lg-5">
                        <div style={{ "textAlign": "center", "fontSize": "90%","paddingTop":"30px","paddingBottom":"20px" }}>
                            <h3>Payment</h3>
                            <p>Do a direct EFT into our bank account or make<br />payment through PayFast's secure payment options.</p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div style={{ "textAlign": "center", "fontSize": "90%","paddingTop":"30px","paddingBottom":"20px" }}>
                            <h3>Contact us</h3>
                            <p>+27 (0)83 460 3773<br />info@velskoendraai.co.za</p>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div style={{ "textAlign": "center", "fontSize": "90%","padding":"20px" }}>
                            <h3>Delivery</h3>
                            <p>We make use of Aramex to send our orders within SA @ R100.<br />Delivery cost abroad may incur more cost depending on destination.</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Intro