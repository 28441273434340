import React from 'react'

/*
const checkLength = (str) => {
    if (str.length > 20) {
        return str.substr(0, 20) + '...';
    } else {
        return str.substr(0, 20);
    }

}*/

const Categories = (props) => {
    //console.log(props.selectedCatId)

    return (
        <div className="container">
            {props.selectedCatId === 0 ?
                <div className="row">
                    {props.categories.map(e => {
                        return (
                            <div className="col-lg" key={e.cat_id}>
                                <div style={{ "textAlign": "center" }}>
                                    {e.cat_image ?
                                        <img className="categoryImage" src={props.imgUrl + '/' + e.cat_image} alt="" onClick={() => props.setSelection('setCatId', e.cat_id)} />
                                        :
                                        <img className="categoryImage" src={"/blank2.jpg"} alt="" onClick={() => props.setSelection('setCatId', e.cat_id)} />
                                    }
                                    <div className="spacer"></div>
                                    <h4 className="cath1">{e.cat_code.toLowerCase()}</h4>
                                    
                                    <p style={{ "textAlign": "center" }}>
                                        <button className="btn btn-warning btn-lg" onClick={() => props.setSelection('setCatId', e.cat_id)}>
                                            <i className="fas fa-box-open"></i> View</button>
                                    </p>
                                    <div className="spacer"></div>
                                    <div style={{ "textAlign": "center", "fontSize": "100%" }}>
                                        {e.description}
                                    </div>

                                </div>

                            </div>
                        )
                    })}
                </div>
                : null

            }
        </div>
    )
}

export default Categories