import React from 'react'

const ResetMessage = (props) => {
    return (

        <div className="alert alert-info" role="alert">{props.responseMessage}</div>

    )
}

export default ResetMessage