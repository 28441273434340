import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ErrorMessage from './ErrorMessage'
import PrettyDeliveryAddress from './PrettyDeliveryAddress'
import Orders from './Orders'
class User extends Component {  

    render() {
        window.scrollTo(0, 0);

        return (<div className="container">
            <div>
                {this.props.errorMessage ?
                    <ErrorMessage errorMessage={this.props.errorMessage} clearErrorMessage={this.props.clearErrorMessage} />
                    :
                    null
                }
            </div>
            <div className="row">
                    <div className="col-lg-2">
                        <div>
                            <Link className="btn btn-link" to="/" style={{ "width": "auto", "padding": "0" }}>Home > </Link>
                            <span className="btn btn-link" style={{ "width": "auto", "padding": "0", "fontWeight": "bold" }}>USER</span>
                        </div>
                        <div className="spacer"></div>
                    </div>
                </div>
            <div className="row">
                <div className="col-lg-4">
                    {this.props.buyer.webid > 0 ?
                        <div>
                            <h2>Account</h2>
                            <p><button className="btn btn-default" onClick={() => this.props.handleLogout()}>Logout</button><Link className="btn btn-secondary" to="/profile">Edit my details</Link>
                            {this.props.cartCostItems.length >0 ?
                                <Link className="btn btn-warning" to="/cart">My Cart</Link> : null
    }
                            </p>
                            <p><strong>Name</strong><br />{this.props.buyer.naam} {this.props.buyer.van}</p>
                            <p><strong>E-mail Address</strong><br />{this.props.buyer.eposadres}</p>
                            <p><strong>Contact nr</strong><br />{this.props.buyer.scell}</p>
                            <strong>Delivery Address</strong><br /><PrettyDeliveryAddress sdeladres={this.props.buyer.sdeladres} />
                            <strong>{this.props.buyer.delcountry}</strong>
                        </div>
                        :
                        <div>
                            <h2>Login</h2>

                            {this.props.loggingIn ?
                                <div className="container">
                                    <div className="spacer"></div>
                                    <div className="d-flex align-items-center">
                                        <strong>Logging in...</strong>
                                        <div className="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                                    </div>
                                </div>
                                :
                                <form method="post" name="form" onSubmit={(e) => this.props.handleLoginSubmit(e)}>
                                    <div className="form-group row">
                                        <label htmlFor="username" className="col-md-4 col-form-label text-md-left">E-mail</label>
                                        <div className="col-md-8">
                                            <input type="email" className="form-control" name="buyer.eposadres" required value={this.props.buyer.eposadres} onChange={(e) => this.props.changeHandler(e)} />
                                        </div>
                                    </div>
                                    <div className='form-group row'>
                                        <label htmlFor="password" className="col-md-4 col-form-label text-md-left">Password</label>
                                        <div className="col-md-8">
                                            <input type="password"
                                                className="form-control"
                                                name="wagwoord"
                                                value={this.props.wagwoord}
                                                required
                                                onChange={(e) => this.props.changeHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="btn" className="col-md-4 col-form-label text-md-left">&nbsp;</label>
                                        <div className="col-md-8"><button className="btn btn-warning">Login</button>
                                        <Link className="btn btn-default" to="/reset">Reset password</Link></div>

                                    </div>
                                    <div className="spacer"></div>
                                    <div className="spacer"></div>
                                    <div className="spacer"></div>
                                    <div className="spacer"></div>
                                    <div className="spacer"></div>
                                </form>
                            }
                        </div>
                    }
                </div>
                <div className="col-lg-8">
                    {this.props.buyer.webid > 0 ?
                        <div>
                            {this.props.orders.length > 0?
                            <div>
                                <h2 style={{ "textAlign": "center" }}>My Orders</h2>
                                <Orders orders={this.props.orders} shopUrl={this.props.shopUrl} buyer={this.props.buyer} />
                            </div>
                            : <div style={{ "textAlign": "center" }}>
                                    <h2 style={{ "textAlign": "center" }}>Orders</h2>
                                    <button className="btn btn-secondary" onClick={()=>this.props.loadOrders()}>View my orders</button>
                              </div> }
                        </div>
                        :
                        <div>
                            <h2 style={{ "textAlign": "center" }}>Not registered yet?</h2>
                            <p style={{ "textAlign": "center" }}>Create a quick profile for faster checkout.</p>
                            <p style={{ "textAlign": "center" }}><Link className="btn btn-default" to="/profile">Register now</Link></p>
                        </div>
                    }
                </div>
            </div>

        </div>)
    }

}

export default User