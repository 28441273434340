import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ErrorMessage from './ErrorMessage'
class UserProfile extends Component {



    render() {
        window.scrollTo(0, 0);
        return (<div className="container">
            <div>
                {this.props.errorMessage ?
                    <ErrorMessage errorMessage={this.props.errorMessage} clearErrorMessage={this.props.clearErrorMessage} />
                    :
                    <div className="row">
                        <div className="col-md-2">
                            <div>
                                <Link className="btn btn-link" to="/" style={{ "width": "auto", "padding": "0" }}>Home > </Link>
                                <span className="btn btn-link" style={{ "width": "auto", "padding": "0", "fontWeight": "bold" }}>PROFILE</span>
                            </div>
                            <div className="spacer"></div>
                        </div>
                    </div>
                }
            </div>
            {!this.props.saving ?

                <div className="row">
                    <div className="col-md-8">
                        <div>
                            <h2>Profile</h2>
                            <p><strong>All fields are required for successful and timeous delivery.</strong></p>
                            <form method="post" name="form" onSubmit={(e) => this.props.handleProfileSubmit(e)}>
                                <div className="form-group row">
                                    <label htmlFor="username" className="col-md-4 col-form-label text-md-left">E-mail</label>
                                    <div className="col-md-6">
                                        {this.props.buyer.webid === 0 ?
                                            <input type="email" className="form-control" name="buyer.eposadres" required value={this.props.buyer.eposadres} onChange={(e) => this.props.changeHandler(e)} />
                                            :
                                            <input type="email" className="form-control" name="buyer.eposadres" readOnly value={this.props.buyer.eposadres} onChange={(e) => this.props.changeHandler(e)} />
                                        }
                                    </div>
                                </div>
                                {this.props.buyer.webid === 0 ?
                                    <div>
                                        <div className='form-group row'>
                                            <label htmlFor="password" className="col-md-4 col-form-label text-md-left">Password</label>
                                            <div className="col-md-6">
                                                <input type="password"
                                                    className="form-control"
                                                    autoComplete="new-password"
                                                    name="wagwoord"
                                                    value={this.props.wagwoord}
                                                    required
                                                    onChange={(e) => this.props.changeHandler(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className='form-group row'>
                                            <label htmlFor="wagwoord2" className="col-md-4 col-form-label text-md-left">Retype Password</label>
                                            <div className="col-md-6"><input type="password"
                                                className="form-control"
                                                name="wagwoord2"
                                                value={this.props.wagwoord2}
                                                required
                                                onChange={(e) => this.props.changeHandler(e)}
                                            /></div>
                                        </div>
                                        <input type="hidden" name="action" id="action" value="insert" />
                                    </div>

                                    : <input type="hidden" name="action" id="action" value="update" />}
                                <div className="form-group row">
                                    <label htmlFor="naam" className="col-md-4 col-form-label text-md-left">Name</label>
                                    <div className="col-md-6">
                                        <input type="text" className="form-control" name="buyer.naam" required value={this.props.buyer.naam} onChange={(e) => this.props.changeHandler(e)} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="van" className="col-md-4 col-form-label text-md-left">Surname</label>
                                    <div className="col-md-6">
                                        <input type="text" className="form-control" name="buyer.van" required value={this.props.buyer.van} onChange={(e) => this.props.changeHandler(e)} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="scell" className="col-md-4 col-form-label text-md-left">Mobile nr</label>
                                    <div className="col-md-6">
                                        <input type="text" className="form-control" name="buyer.scell" required value={this.props.buyer.scell} onChange={(e) => this.props.changeHandler(e)} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="sdeladres" className="col-md-4 col-form-label text-md-left">Delivery Address</label>
                                    <div className="col-md-6">
                                        <textarea type="text" className="form-control" name="buyer.sdeladres" rows={3} required value={this.props.buyer.sdeladres}
                                            onChange={(e) => this.props.changeHandler(e)} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="delcountry" className="col-md-4 col-form-label text-md-left">Delivery Country - <em>Deliveries outside South Africa will incur more cost</em></label>
                                    <div className="col-md-6">
                                        <input type="text" className="form-control" name="buyer.delcountry" required value={this.props.buyer.delcountry} onChange={(e) => this.props.changeHandler(e)} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="btn" className="col-md-4 col-form-label text-md-left">&nbsp;</label>
                                    <div className="col-md-6"><button className="btn btn-warning">Save Profile</button></div>

                                </div>
                            </form>
                        </div>

                    </div>
                    <div className="col-md-4">
                        <h2 style={{ "textAlign": "center" }}>Privacy Statement</h2>
                        <p style={{ "textAlign": "center" }}>We encrypt all records and we will not sell or market your information in any way.</p>
                        <p style={{ "textAlign": "center" }}>We respect your privacy as much as we value our own.</p>
                        {this.props.buyer.webid === 0 ?
                            <p style={{ "textAlign": "center" }}><Link className="btn btn-default" to="/user">Already registered? Login now</Link></p>
                            :
                            null
                        }
                    </div>
                </div>
                :
                <div className="d-flex align-items-center">
                    <strong>Saving profile...</strong>
                    <div className="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                </div>
            }
        </div>)
    }

}

export default UserProfile