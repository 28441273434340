import React from 'react'
import PrettyDeliveryAddress from './PrettyDeliveryAddress'
import SuccessMessage from './SuccessMessage'
import { Link } from 'react-router-dom'
const Cart = (props) => {

    let totalItems = 0
    let totalCost = 0

    props.cartCostItems.forEach(e => {
        totalItems = totalItems + (e.qty * 1)
        totalCost = totalCost + (e.rprice * e.qty)
    });

    window.scrollTo(0,0)
    
    return (<div className="container">
        {props.submitting ?
            <div className="d-flex align-items-center">
                <strong>Submitting order...</strong>
                <div className="spinner-border ml-auto" role="status" aria-hidden="true"></div>
            </div>
            :
            <div>

                {props.successMessage ?
                    <div>
                        <div className="row">
                            <div className="col-lg-2">
                                <div>
                                    <Link className="btn btn-link" to="/" style={{ "width": "auto", "padding": "0" }}>Home > </Link>
                                    <span className="btn btn-link" style={{ "width": "auto", "padding": "0", "fontWeight": "bold" }}>CART</span>
                                </div>
                                <div className="spacer"></div>
                            </div>
                        </div>
                        <SuccessMessage successMessage={props.successMessage} clearSuccessMessage={props.clearSuccessMessage} />
                        <div className="spacer"></div>
                        <div className="spacer"></div>
                        <div className="spacer"></div>
                        <div className="spacer"></div>
                    </div>
                    :
                    <div>
                        <div className="row">
                            <div className="col-lg-2" key={0}>
                                <div>
                                    <Link className="btn btn-link" to="/" style={{ "width": "auto", "padding": "0" }}>Home > </Link>
                                    <span className="btn btn-link" style={{ "width": "auto", "padding": "0", "fontWeight": "bold" }}>CART</span>
                                </div>
                                <div className="spacer"></div>
                                <div><h4 style={{ "textAlign": "left" }}>Shopping Cart</h4></div>
                                <div className="spacer"></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                {props.cartCostItems.length > 0 ?
                                    <table className="mytable">
                                        <thead style={{ "backgroundColor": "#dddddd", "color": "#555555" }}>
                                            <tr>

                                                <th scope="col">ITEM</th>
                                                <th scope="col" style={{ "textAlign": "center" }}>QTY</th>
                                                <th>&nbsp;</th>
                                                <th scope="col" style={{ "textAlign": "right" }}>PRICE</th>
                                                <th scope="col" style={{ "textAlign": "center" }}>TOTAL</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {props.cartCostItems.map(e => {
                                                return (
                                                    <tr key={e.costitemid}>

                                                        <td style={{ "width": "50%" }}>{e.description}</td>
                                                        <td style={{ "textAlign": "center" }}>
                                                            <input type="number" pattern='[0-9]'
                                                                className="form-control"
                                                                name={e.costitemid}
                                                                value={e.qty}
                                                                step={1}
                                                                min={1}
                                                                onChange={(e) => props.changeQtyHandler(e)}
                                                                style={{ "width": "60px", "textAlign": "center", "margin": "auto" }}
                                                            />
                                                        </td>
                                                        <td><button className="btn btn-default btn-sm" onClick={() => props.rfc(e.costitemid)}><i className="fas fa-times"></i></button></td>
                                                        <td style={{ "textAlign": "right", "width": "15%" }}>R {e.rprice}</td>
                                                        <td style={{ "textAlign": "center", "width": "12%" }}>R {e.rprice * e.qty}</td>
                                                    </tr>
                                                )
                                            })}

                                            <tr>
                                                <td>&nbsp;</td>
                                                <td style={{ "textAlign": "center" }}>{totalItems} {totalItems > 1 ? "items" : "item"}</td>
                                                <td style={{ "textAlign": "center" }}></td>
                                                <td>&nbsp;</td>
                                                <td style={{ "textAlign": "center" }}>R {totalCost}</td>
                                            </tr>
                                            <tr><td colSpan={3} style={{ "border": "0px" }}>&nbsp;</td>
                                                <td style={{ "textAlign": "right", "fontWeight": "bold" }}>Delivery: {props.deliveryMethod}</td>
                                                <td style={{ "textAlign": "center", "fontWeight": "bold" }}>R {props.deliveryCost}</td>
                                            </tr>
                                            <tr><td colSpan={3} style={{ "border": "0px" }}>&nbsp;</td>
                                                <td style={{ "textAlign": "right", "fontWeight": "bold" }}>TOTAL</td>
                                                <td style={{ "textAlign": "center", "fontWeight": "bold" }}>R {totalCost + props.deliveryCost}</td>
                                            </tr>
                                        </tbody>

                                    </table>
                                    : <h4>Cart is empty!</h4>}
                            </div>
                        </div>
                        <div className="spacer"></div><div className="spacer"></div>

                        {props.cartCostItems.length > 0 ?
                            <div className="row">
                                <div className="col-lg-3">
                                    <h4 style={{ "textAlign": "left" }}>Delivery Address</h4>
                                    <PrettyDeliveryAddress sdeladres={props.buyer.sdeladres} />
                                    <strong>{props.buyer.delcountry}</strong>

                                </div>
                                <div className="col-lg-3">
                                    <div className="mobispacer"></div>
                                    <h4 style={{ "textAlign": "left" }}>Payment Method: {props.paymentMethod}</h4>
                                    <p>Do a direct EFT or pay through PayFast.</p>
                                    {props.paymentMethod === "EFT" ?
                                        <div>
                                            <button className="btn btn-warning">
                                                <i className="far fa-money-bill-alt"></i> EFT
                                        </button>
                                            <button className="btn btn-default-outline" onClick={() => props.setPaymentMethod('CARD')}>
                                                <i className="far fa-credit-card"></i> PayFast
                                        </button>
                                        </div>
                                        :
                                        <div>
                                            <button className="btn btn-default-outline" onClick={() => props.setPaymentMethod('EFT')}>
                                                <i className="far fa-money-bill-alt"></i> EFT
                                        </button>
                                            <button className="btn btn-warning">
                                                <i className="far fa-credit-card"></i> PayFast
                                        </button>
                                        </div>
                                    }

                                </div>
                                <div className="col-lg-3">
                                    <div className="mobispacer"></div>
                                    <h4 style={{ "textAlign": "left" }}>Delivery Method: {props.deliveryMethod}</h4>
                                    <p>Please select delivery method. Shipments abroad may incur more cost, depending on destination.</p>
                                    {props.deliveryMethod === "COURIER" ?
                                        <div>
                                            <button className="btn btn-warning">
                                                COURIER
                                            </button>
                                            <button className="btn btn-default-outline" onClick={() => props.setDeliveryMethod('COLLECT')}>
                                                COLLECT
                                            </button>
                                        </div>
                                        :
                                        <div>
                                            <button className="btn btn-default-outline" onClick={() => props.setDeliveryMethod('COURIER')}>
                                                COURIER
                                    </button>
                                            <button className="btn btn-warning">
                                                COLLECT
                                    </button>
                                        </div>
                                    }

                                </div>
                                <div className="col-lg-3" style={{ "textAlign": "center" }}>
                                    <div className="mobispacer"></div><div className="mobispacer"></div>
                                    {props.buyer.webid > 0 ?
                                        <div>
                                            <textarea className="form-control" placeholder="Request or comments regarding your order / delivery / collection?" name="buyer.extra" value={props.buyer.extra} onChange={(e)=>props.changeHandler(e)} />
                                            <div className="spacer"></div>
                                            <input type="hidden" name="TotalOrderValue" id="TotalOrderValue" value={totalCost + props.deliveryCost} />
                                            <button className="btn btn-warning btn-lg" onClick={() => props.submitOrder()}>
                                                <i className="fas fa-sign-in-alt"></i> SUBMIT ORDER
                                            </button>
                                        </div>
                                        :
                                        <div>
                                            <Link className="btn btn-secondary btn-lg" to="/user"><i className="fas fa-user"></i>  Please Login / Register</Link>
                                        </div>
                                    }
                                </div>
                            </div>
                            : null}
                    </div>
                }


            </div>}
        <div className="spacer"></div>
        <div className="spacer"></div>
    </div>)
}

export default Cart