import React from 'react'

const SubCategories = (props) => {
    //console.log(props.imgUrl)

    return (
        <div className="container">
            {props.selectedSubCatId === 0 ?
                <div className="row">
                    {props.subcategories.map(e => {
                        return (
                            <div className="col-md-2" key={e.subcat_id} onClick={() => props.setSelection('setSubCatId', e.subcat_id)}>
                                <div style={{ "textAlign": "center" }}>
                                    {e.subcat_image ?
                                        <img className="subcategoryImage" src={props.imgUrl + '/' + e.subcat_image} alt="" />
                                        :
                                        <img className="subcategoryImage" src={"/blank2.jpg"} alt="" />
                                    }
                                    <div className="spacer"></div>
                                    <h4>{e.subcat_code.toLowerCase()}</h4>
                                    <div className="spacer"></div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                :
                null}
        </div>
    )
}

export default SubCategories