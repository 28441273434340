import React, { Component } from 'react'

function decode_stage (i) {
    if (i === "1") { return "Awaiting payment"}
    if (i === "2") { return "Processing"}
    if (i === "3") { return "Paid"}
    if (i === "4") { return "Dispatched / Ready for collection"}
    if (i === "5") { return "Cancelled"}
}
class Orders extends Component {

    state = {
        orderid_to_pay: "0",
    }

    componentDidMount() {
        if (localStorage.getItem('orderid_to_pay')) {
            this.setState({
                orderid_to_pay: localStorage.getItem('orderid_to_pay')
            })
        }
    }
    render() {
        //console.log(localStorage.getItem('orderid_to_pay'), this.state.orderid_to_pay)
        return (
            <div>
                
                <table className="mytable">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th style={{"textAlign":"center"}}>Total Value</th>
                    <th style={{"textAlign":"center"}}>Order Status</th>
                </tr>
                </thead>
                <tbody>
                    {this.props.orders.map(e => {
                        return (
                            <tr key={e.OrderID}>
                                <td>ORDER000{e.OrderID}</td>
                                <td>{e.OrderDate}</td>
                                <td style={{"textAlign":"center"}}>R{e.TotalOrderValue}</td>
                                <td style={{"textAlign":"center"}}>{decode_stage(e.order_stage)}</td>
                                <td style={{"textAlign":"center"}}>
                                    {e.order_stage === "1" ?
                                    <form action="https://www.payfast.co.za/eng/process" method="POST">
                                        <input type="hidden" name="merchant_id" value="15549254" />
                                        <input type="hidden" name="merchant_key" value="1v9vrc45hds3i" />
                                        <input type="hidden" name="amount" value={e.TotalOrderValue} />
                                        <input type="hidden" name="item_name" value={"Velskoendraai SHOP ORDER000"+e.OrderID} />
                                        <input type="hidden" name="name_first" value={this.props.buyer.naam} />
                                        <input type="hidden" name="name_last" value={this.props.buyer.van} />
                                        <input type="hidden" name="email_address" value={this.props.buyer.eposadres} />
                                        <input type="hidden" name="return_url" value={this.props.shopUrl+"success"} />
                                        <input type="hidden" name="cancel_url" value={this.props.shopUrl+"user"} />
                                        <button className="btn btn-warning btn-sm" onClick={()=>{localStorage.setItem('orderid_to_pay', e.OrderID)}}>Pay now</button>
                                    </form>
                                    : null }
                                </td>
                            </tr>
                            )
                    })}
                </tbody>
                    
    
            </table>
            </div>
            
    
        )
    }
    
}

export default Orders