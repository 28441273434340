import React from 'react'

const Container = ({ component: Component, ...rest }) => {
    return (<div className="container-fluid">
        <div className="spacer"></div>
        <Component {...rest} />
        <div className="spacer"></div>
        <div className="spacer"></div>
        
    </div>)
}

export default Container