import React, { Component } from 'react'
import ErrorMessage from './ErrorMessage'
import Categories from './Categories'
import SubCategories from './SubCategories'
import CostItems from './CostItems'
import BreadCrumbs from './BreadCrumbs'
class ShopItemsBrowser extends Component {

    render() {
        

        return (
            <div ref={this.props.myRef}>
                <div className="container">
                    {this.props.errorMessage ?
                        <ErrorMessage errorMessage={this.props.errorMessage} clearErrorMessage={this.props.clearErrorMessage} />
                        :
                        null
                    }
                </div>
                <BreadCrumbs 
                    categories={this.props.categories}
                    subcategories={this.props.subcategories}
                    costitems={this.props.costitems}
                    selectedCatId={this.props.selectedCatId}
                    selectedSubCatId={this.props.selectedSubCatId}
                    selectedCostitemId={this.props.selectedCostitemId} 
                    setSelection={this.props.setSelection}
                    searchString={this.props.searchString}
                    searchResultsShow={this.props.searchResultsShow}
                />
                
                <div>
                    {this.props.costitems.length === 0 && !this.props.loading ?
                    <Categories
                        categories={this.props.categories}
                        imgUrl={this.props.imgUrl}
                        selectedCatId={this.props.selectedCatId}
                        selectedSubCatId={this.props.selectedSubCatId}
                        setSelection={this.props.setSelection}
                    />
                    : null }
                </div>
                <div>
                    {this.props.loading ?
                        <div className="container">
                            <div className="spacer"></div>
                            <div className="d-flex align-items-center">
                                <strong>Loading...</strong>
                                <div className="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                            </div>
                        </div>
                        :
                        <SubCategories
                            subcategories={this.props.subcategories}
                            imgUrl={this.props.imgUrl}
                            selectedCatId={this.props.selectedCatId}
                            selectedSubCatId={this.props.selectedSubCatId}
                            setSelection={this.props.setSelection}
                        />
                    }

                </div>
                <div>
                    {this.props.costitems.length > 0 ?
                        <CostItems
                            imgUrl={this.props.imgUrl}
                            setSelection={this.props.setSelection}
                            costitems={this.props.costitems}
                            selectedCostitemId={this.props.selectedCostitemId}
                            atc={this.props.atc}
                        />
                        : null}
                </div>

            </div>
        )
    }
}

export default ShopItemsBrowser