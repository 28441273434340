import React from 'react'

function getMyTitle(ttype, arr, compareId) {

    let myTitleArray = []

    if (ttype === 'categories') {
        myTitleArray = arr.filter(e => e.cat_id === compareId)
        if (myTitleArray.length === 1) {
            return myTitleArray[0].cat_code
        }
    }

    if (ttype === 'subcategories') {
        myTitleArray = arr.filter(e => e.subcat_id === compareId)
        if (myTitleArray.length === 1) {
            return myTitleArray[0].subcat_code
        }
    }

    if (ttype === 'costitems') {
        myTitleArray = arr.filter(e => e.costitemid === compareId)
        if (myTitleArray.length === 1) {
            return myTitleArray[0].description
        }
    }

}

const BreadCrumbs = (props) => {

    let mybreadcrumb, h1title = ''

    if (props.selectedCatId > 0 && props.selectedSubCatId === 0) {
        h1title = getMyTitle('categories', props.categories, props.selectedCatId)
        mybreadcrumb = (
            <div>
                <button className="btn btn-link" style={{ "width": "auto", "padding": "0" }} onClick={() => props.setSelection('setCatId', 0)}>Home > </button>
                <span className="btn btn-link" style={{ "width": "auto", "padding": "0", "fontWeight": "bold" }}>
                    {h1title}</span>
            </div>

        )
    }

    if (props.selectedCatId > 0 && props.selectedSubCatId > 0 && props.selectedCostitemId === 0) {
        h1title = getMyTitle('subcategories', props.subcategories, props.selectedSubCatId)
        mybreadcrumb = (
            <div>
                <button className="btn btn-link" style={{ "width": "auto", "padding": "0" }} onClick={() => props.setSelection('setCatId', 0)}>Home > </button>
                <button
                    className="btn btn-link"
                    style={{ "width": "auto", "padding": "0" }}
                    onClick={() => props.setSelection('setSubCatId', 0)}>
                    {getMyTitle('categories', props.categories, props.selectedCatId)} >
                </button>
                <span className="btn btn-link" style={{ "width": "auto", "padding": "0", "fontWeight": "bold" }}>
                    {h1title}
                </span>
            </div>
        )
    }

    if (props.selectedCatId > 0 && props.selectedSubCatId > 0 && props.selectedCostitemId > 0) {
        h1title = getMyTitle('costitems', props.costitems, props.selectedCostitemId)
        mybreadcrumb = (
            <div>
                <button className="btn btn-link" style={{ "width": "auto", "padding": "0" }} onClick={() => props.setSelection('setCatId', 0)}>Home > </button>
                <button
                    className="btn btn-link"
                    style={{ "width": "auto", "padding": "0" }}
                    onClick={() => props.setSelection('setSubCatId', 0)}>
                    {getMyTitle('categories', props.categories, props.selectedCatId)} >
                </button>
                <button className="btn btn-link"
                    style={{ "width": "auto", "padding": "0" }}
                    onClick={() => props.setSelection('setCostitemId', 0)}>
                    {getMyTitle('subcategories', props.subcategories, props.selectedSubCatId)} >
                </button>
                <span className="btn btn-link" style={{ "width": "auto", "padding": "0", "fontWeight": "bold" }}>{h1title}</span>
            </div>
        )
    }

    if (props.searchResultsShow) {
        h1title = "Search"
        mybreadcrumb = (
            <div>
                <button className="btn btn-link" style={{ "width": "auto", "padding": "0" }} onClick={() => props.setSelection('setCatId', 0)}>Home > </button>
                <span className="btn btn-link" style={{ "width": "auto", "padding": "0", "fontWeight": "bold" }}>{h1title}</span>
            </div>
        )
    }

    if (props.searchResultsShow && props.selectedCostitemId) {
        h1title = "Search"
        mybreadcrumb = (
            <div>
                <button className="btn btn-link" style={{ "width": "auto", "padding": "0" }} onClick={() => props.setSelection('setCatId', 0)}>Home > </button>
                <button className="btn btn-link" style={{ "width": "auto", "padding": "0" }} onClick={() => props.setSelection('setCostitemId', 0)}>Search results > </button>
                <span className="btn btn-link" style={{ "width": "auto", "padding": "0", "fontWeight": "bold" }}>{h1title}</span>
            </div>
        )
    }
    //{props.selectedCatId} | {props.selectedSubCatId} | {props.selectedCostitemId} >> 
    return (

        <div className="container">
            
            {props.searchResultsShow ?
                <div>
                    <div style={{ "fontSize": "110%" }}>{mybreadcrumb}</div>
                    <div className="spacer"></div>
                    <div><h4 style={{ "textAlign": "left" }}>{h1title}: <em>{props.searchString}</em></h4></div>
                    <div className="spacer"></div>
                </div>
                :
                null
            }

            {props.selectedCatId > 0 ?
                <div>
                    <div style={{ "fontSize": "110%" }}>{mybreadcrumb}</div>
                    <div className="spacer"></div>
                    <div><h4 style={{ "textAlign": "left" }}>{h1title}</h4></div>
                    <div className="spacer"></div>
                </div>
                : null}
        </div>

    )
}

export default BreadCrumbs