import React from 'react'

const ErrorMessage = (props) => {
    return (

        <div className="alert alert-warning" role="alert">
            <p>{props.errorMessage}</p> 
            <p><button className="btn btn-secondary" onClick={() => props.clearErrorMessage()}><i className="far fa-window-close"></i> Close</button></p>
        </div>

    )
}

export default ErrorMessage