import React from 'react'

function goToTop() {
    window.scrollTo(0, 0);
}
const CostItems = (props) => {
    let itemToFindArray = []
    let itemObj = {}
    if (props.selectedCostitemId > 0) {
        itemToFindArray = props.costitems.filter(e => e.costitemid === props.selectedCostitemId)
    }
    if (itemToFindArray.length === 1) {
        itemObj = itemToFindArray[0]
    }

    if (props.selectedCostitemId > 0) { window.scrollTo(0, 0)}

    return (
        <div className="container">
            {props.selectedCostitemId > 0 ?
                <div>
                    
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="costitemwrapper">

                                {itemObj.image1 ?
                                    <img src={props.imgUrl + "/" + itemObj.image1} alt="" className="costitemImage" />
                                    :
                                    <img src="/blank3.jpg" alt="" className="costitemImage" />
                                }

                                <div className="costitemDetailViewTitle">
                                    {itemObj.comments ? <p>{itemObj.comments}</p> : <p>{itemObj.description}</p> }
                                </div>
                                <div style={{ "backgroundColor": "#ffffff" }}>
                                    <p className="priceTag">R {itemObj.rprice}</p>
                                    {itemObj.soh === "0" ?
                                        <div>
                                            <button className="btn btn-warning" onClick={() => props.atc(itemObj.costitemid)}><i className="fas fa-cart-arrow-down"></i></button>
                                        </div>
                                        :
                                        <div>
                                            <p>Out of stock</p>
                                            <button className="btn btn-secondary" disabled><i className="fas fa-cart-arrow-down"></i></button>
                                        </div>
                                    }
                                    <div className="spacer"></div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="row">
                    
                    {props.costitems.map(e => {
                        return (<div className="col-lg-2" key={e.costitemid}>
                            <div className="costitemwrapper">

                                {e.image1 ?
                                    <img src={props.imgUrl + "/" + e.image1} alt="" className="costitemImage" onClick={() => props.setSelection('setCostitemId', e.costitemid)} />
                                    :
                                    <img src="/blank3.jpg" alt="" className="costitemImage" onClick={() => props.setSelection('setCostitemId', e.costitemid)} />
                                }

                                <div className="costitemTitle">
                                    <p>{e.description}</p>
                                </div>
                                <div style={{ "backgroundColor": "#ffffff" }}>
                                    <p className="priceTag">R {e.rprice}</p>
                                    {e.soh === "0" ?
                                        <div>
                                            <button className="btn btn-warning" onClick={() => props.atc(e.costitemid)}><i className="fas fa-cart-arrow-down"></i></button>
                                            <button className="btn btn-secondary" onClick={() => props.setSelection('setCostitemId', e.costitemid)}><i className="fas fa-search-plus"></i></button>
                                        </div>
                                        :
                                        <div>
                                            <button className="btn btn-secondary" onClick={() => props.setSelection('setCostitemId', e.costitemid)}><i className="fas fa-search-plus"></i></button>
                                        </div>
                                    }
                                    <div className="spacer"></div>
                                </div>

                            </div>
                        </div>)
                    })}
                </div>
            }
            <div className="spacer"></div><div className="spacer"></div><div className="spacer"></div>
            <div style={{"textAlign":"center"}}><button className="btn btn-default" onClick={()=>goToTop()}><i className="fas fa-arrow-up"></i> Go to top</button></div>
        </div>
    )
}

export default CostItems