import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import axios from 'axios'

class Success extends Component {

    state = {
        orderid_success: "-1",
    }

    processOrderStatus() {

        let authConfig = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + window.btoa(this.props.buyer.sessionToken)
        }

        axios.post(this.props.shopUrl + '' + this.props.apiRoot + '/orderprocess.php', {
            webid: this.props.buyer.webid,
            orderid: this.state.orderid_success,
            action: 'orderprocess'
        }, { headers: authConfig })
        .then(res => {
            //console.log(res.data)
            //if (res.data[0].success) {
                this.setState({
                    orderid_success: "0"
                })
                localStorage.removeItem('orderid_to_pay')
            //} 
        })
    }

    processOrderStatusSilently() {
        this.setState({
            orderid_success: "0"
        })
    }

    componentDidMount() {
        let refString = document.referrer

        if (refString.indexOf(".payfast.co.za")>2) {
            if (localStorage.getItem('orderid_to_pay')) {
                this.setState({
                    orderid_success: localStorage.getItem('orderid_to_pay')
                })           
            } else {
                this.setState({
                    orderid_success: "-998"
                })
            }
        } else {
            this.setState({
                orderid_success: "-999"
            })
            localStorage.removeItem('orderid_to_pay')
        }

    }

    render() {
        
        return (
            localStorage.getItem('orderid_to_pay')?
            <div style={{"height":"100vh","backgroundColor":"#ffffff","textAlign":"center"}}>
                {this.state.orderid_success === "0"?
                    
                    <Redirect to="/" />
                :
                    <div>
                        <div>
                            {this.state.orderid_success === "-998" ? 
                            <div>
                                <h1>PayFast transaction</h1>
                                <p>PayFast transaction was captured for your Order. Please check your mailbox for confirmation from PayFast.</p>
                                <p>Your order status will be updated shortly and you'll be notified accordingly.</p>
                                <button className="btn btn-secondary btn-lg" onClick={()=> this.processOrderStatusSilently()}><i className="far fa-window-close"></i> Close</button>
                            </div> : null}
                        </div>
                        
                        <div>
                            {this.state.orderid_success !== "-998" && this.state.orderid_success !== "-999"? 
                            <div>
                                <h1>PayFast transaction</h1>
                                <p>PayFast transaction for Order ID000{this.state.orderid_success} was captured. Please check your mailbox for confirmation from PayFast.</p>
                                <p>Your order status will be updated shortly and you'll be notified accordingly.</p>
                                <button className="btn btn-secondary btn-lg" onClick={()=> this.processOrderStatus()}><i className="far fa-window-close"></i> Close</button>
                            </div>
                            : null }
                        </div>
                    </div>
                }                
            </div>
            : <Redirect to="/" />
        )
    }
}

export default Success